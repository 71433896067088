<template>
  <b-modal
      v-model="show"
      content-class="package-modal rounded-8 assign-driver-modal"
      dialog-class="wameed-modal-md"
      no-fade
  >
    <template slot="modal-header-close">
      <close-icon/>
    </template>
    <template slot="modal-title">
      <div class="d-block text-center">
        <h3 class="text-reg-18">
          {{ $t('chats.actions.new_chat') }}
        </h3>
      </div>
    </template>


    <div class="body">

      <div class="search-header grid-col-1">

        <div class="w-full">
          <text-input
              id="login-email"
              v-model="search"
              icon="search-icon"
              is-append
              input-classes="text-reg-14 "
              name="search"
              :placeholder="$t('common.search') + ' ...'"
              field-classes="mb-0 "
          />
        </div>
      </div>

      <div class="driver-list">
        <div
            v-for="user in allAdmins"
            :key="user.id"
            @click="()=>addClick(user)"
        >

          <admin-card
              :class="'onHover'"
              :user="user"
              :showBtn="false"
          />
        </div>


      </div>
    </div>


    <template slot="modal-footer" class="d-none">

    </template>
  </b-modal>
</template>

<script>
import {WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import AdminCard from "@/views/chats/components/adminCard";
import {mapGetters} from "vuex";


export default {
  components: {
    AdminCard,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    category: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      search: '',


      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getDrivers: 'vendor/orders/getDrivers',

    }),
    allAdmins() {
      if (this.search)
        return this.getDrivers.filter((admin) => {
          return admin.name.toString().includes(this.search)
        })
      return this.getDrivers;
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.search = '';
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch('vendor/orders/drivers')
    },
    addClick(user) {
      this.$emit('addClick', user);
    },


    closeModal() {
      this.show = false;
      this.search = '';
    },
  },
  created() {
    this.$store.dispatch('admin/settings/sys_users/getChatAdmins')
  },

  mounted() {
    this.loadData();
  }
};
</script>

<style>
.onHover{
  cursor: pointer;
  border: 1px solid transparent ;
}
.onHover:hover {
  border: 1px solid #26A69A ;
}
</style>
